import React from 'react'
import { Link, navigate } from 'gatsby'
import Login from '../../components/login/login'

export default () => {

  const navigateToMemberships = () => {
    navigate('/memberships')
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-200 dark:bg-gray-900">
        <Link to='/'>
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark.svg?color=primary&shade=600"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900 dark:text-white">Sign in to Disco Deals</h2>
          </div>
        </Link>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10">

            <Login onLoggedIn={navigateToMemberships} />

          </div>
        </div>
      </div>
    </>
  )
}
